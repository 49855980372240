import React from "react";
import { Button } from "@mui/material";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import "./ThankYou.css";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PhoneIcon from "@mui/icons-material/Phone";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const ThankYou2 = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (!sessionStorage.getItem("userEmail")) {
      navigate("/");
    } else {
      setLoading(false);
    }
  }, [navigate]);

  return (
    !loading && (
      <div className="thank-you-container">
        <Helmet>
          <title>Thank You | Internship Studio</title>
        </Helmet>
        <div className="thank-you-text">
          <p>
            <CheckCircleIcon />
            <span>You are successfully registered for DM-SAT!</span>
          </p>
        </div>

        <div className="information">
          <p
            style={{
              margin: "0 3em",
            }}
          >
            If you have any questions regarding the DM-SAT program, please do
            not hesitate to contact us.
          </p>
        </div>

        <div className="placements2">
          <Button
            component={"a"}
            href="https://wa.me/918830657968"
            target="_blank"
            variant="contained"
            className="button"
            style={{
              backgroundColor: "#25D366",
            }}
            startIcon={<WhatsAppIcon />}
          >
            Whatsapp Number
          </Button>

          <Button
            component={"a"}
            href="tel:+918830657968"
            target="_blank"
            variant="contained"
            className="button"
            style={{
              backgroundColor: "#0077B5",
              marginLeft: "1em",
            }}
            startIcon={<PhoneIcon />}
          >
            Contact Number
          </Button>
        </div>
      </div>
    )
  );
};

export default ThankYou2;
