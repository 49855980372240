import settings from "../misc/constants";

export const apiFetchJobs = async () => {
    try {
        const response = await fetch(`${settings.api_url}/api/getJobs.php`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });

        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error fetching jobs:", error);
        return { success: false, message: "Failed to fetch jobs" };
    }
};

export const apiFetchPlacements = async () => {
    try {
        const response = await fetch(`${settings.api_url}/api/getPlacements.php`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error fetching placements:", error);
        return { success: false, message: "Failed to fetch placements" };
    }
};

export const apiFetchInternship = async () => {
    try {
        const response = await fetch(`${settings.api_url}/api/getInternship.php`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });

        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error fetching internship:", error);
        return { success: false, message: "Failed to fetch internship" };
    }
};


export const apiFetchCitDate = async () => {
    try {
        const response = await fetch(`${settings.api_url}/api/getCitDate.php`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });

        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error fetching CitDate:", error);
        return { success: false, message: "Failed to fetch CitDate" };
    }
};


