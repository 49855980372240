import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Container,
  Paper,
  Chip,
  Stack,
  styled,
  alpha,
  Avatar
} from '@mui/material';
import {
  Business as BusinessIcon,
  LocationOn as LocationIcon,
  CurrencyRupee as MoneyIcon,
  AccessTime as TimeIcon,
} from '@mui/icons-material';
import { apiFetchJobs } from '../../utils/api';

// Styled Components
const GradientHeader = styled(Box)(({ theme }) => ({
  background: `linear-gradient(to right, ${alpha(theme.palette.warning.light, 0.1)}, ${alpha(theme.palette.warning.light, 0.2)})`,
  padding: theme.spacing(6, 0),
}));

const FilterButton = styled(Button)(({ theme, selected }) => ({
  backgroundColor: selected ? theme.palette.warning.main : theme.palette.common.white,
  color: selected ? theme.palette.common.white : theme.palette.text.secondary,
  fontWeight: 500,
  borderRadius: theme.shape.borderRadius * 2,
  padding: theme.spacing(1, 2),
  '&:hover': {
    backgroundColor: selected 
      ? theme.palette.warning.dark 
      : alpha(theme.palette.warning.light, 0.1),
  },
  '& .MuiChip-root': {
    marginLeft: theme.spacing(1),
  }
}));

const JobCard = styled(Paper)(({ theme }) => ({
  border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
  borderRadius: theme.shape.borderRadius * 2,
  padding: theme.spacing(3),
  transition: 'all 0.3s ease',
  boxShadow: 'none',
  '&:hover': {
    border: `1px solid ${theme.palette.warning.light}`,
    boxShadow: theme.shadows[1],
  },
}));

const IconText = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
  color: theme.palette.text.secondary,
  fontSize: '0.875rem',
  '& svg': {
    fontSize: '1rem',
    color: theme.palette.text.secondary,
  },
}));

const StyledChip = styled(Chip)(({ theme, color = 'default' }) => ({
  borderRadius: theme.shape.borderRadius * 2,
  fontWeight: 500,
  height: 28,
  ...(color === 'warning' && {
    backgroundColor: alpha(theme.palette.warning.main, 0.1),
    color: theme.palette.warning.dark,
  }),
  ...(color === 'success' && {
    backgroundColor: alpha(theme.palette.success.main, 0.1),
    color: theme.palette.success.dark,
  }),
  ...(color === 'default' && {
    backgroundColor: alpha(theme.palette.grey[500], 0.1),
    color: theme.palette.text.secondary,
  }),
}));

const ApplyButton = styled(Button)(({ theme, hovered }) => ({
  padding: theme.spacing(1, 3),
  borderRadius: theme.shape.borderRadius * 2,
  fontWeight: 500,
  borderWidth: 2,
  textDecoration: 'none',
  ...(hovered ? {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.common.white,
    borderColor: theme.palette.warning.main,
    '&:hover': {
      backgroundColor: theme.palette.warning.dark,
      borderColor: theme.palette.warning.dark,
    },
  } : {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.warning.main,
    borderColor: theme.palette.warning.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.warning.light, 0.1),
      borderColor: theme.palette.warning.main,
    },
  }),
}));

// Job listings data
// const JOB_LISTINGS = [
//   {
//     id: "frontend-intern-001",
//     title: "Frontend Developer Intern",
//     company: "TechCorp",
//     location: "Remote",
//     stipend: "20,000/month",
//     type: "Internship",
//     postedDate: "2 days ago",
//     status: "live",
//     applicationLink: "https://forms.google.com/frontend-intern-001"
//   },
//   {
//     id: "uiux-intern-001",
//     title: "UI/UX Design Intern",
//     company: "DesignHub",
//     location: "Bangalore",
//     stipend: "15,000/month",
//     type: "Internship",
//     postedDate: "1 week ago",
//     status: "hired",
//     applicationLink: "https://forms.google.com/uiux-intern-001"
//   },
//   {
//     id: "fullstack-dev-001",
//     title: "Full Stack Developer",
//     company: "WebSolutions",
//     location: "Hybrid - Delhi",
//     stipend: "45,000/month",
//     type: "Full-time",
//     postedDate: "3 days ago",
//     status: "live",
//     applicationLink: "https://forms.google.com/fullstack-dev-001"
//   },
// ];

/*
// Template for adding new job listings - Just copy, paste and modify
{
  id: "unique-id-here",  // Create a unique ID
  title: "Job Title",
  company: "Company Name",
  location: "Location",
  stipend: "Amount/month",
  type: "Internship", // or "Full-time" or other types
  postedDate: "X days/weeks ago",
  status: "live", // or "hired"
  applicationLink: "https://forms.google.com/your-form-link"
},
*/

const JobListings = () => {

  
  
  const [hoveredId, setHoveredId] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState('all');
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchJobs = async () => {
        try {
            const response = await apiFetchJobs();
            if (response.success) {
                setJobs(response.data); 
            } else {
                setError(response.message || "Failed to load jobs.");
            }
        } catch (err) {
            console.error("Fetch Error:", err);
            setError("Something went wrong while fetching jobs.");
        } finally {
            setLoading(false);
        }
    };
    fetchJobs();
}, []);


  const filters = [
    { id: 'all', label: 'All Positions' },
    { id: 'live', label: 'Live' },
    { id: 'hired', label: 'Hired' }
  ];


  const filteredJobs = jobs.filter(job => 
    selectedFilter === 'all' ? true : job.status === selectedFilter
  );

  console.log("Filtered Jobs:", filteredJobs);

  // Helper function to handle apply button click
  const handleApply = (job) => {
    if (job.type === "Internship" && job.applicationLink) {
      window.open(job.applicationLink, '_blank');
    }
  };

  return (
    <Box sx={{ minHeight: '100vh', bgcolor: 'background.default' }}>
      {/* Header with filters */}
      <GradientHeader>
        <Container maxWidth="lg">
          <Typography 
            variant="h3" 
            component="h1" 
            sx={{ 
              fontWeight: 700, 
              color: 'text.primary',
              mb: 3,
              fontSize: { xs: '2rem', md: '2.5rem' }
            }}
          >
            Find Your Next Opportunity
          </Typography>
          
          <Stack direction="row" spacing={1} flexWrap="wrap" gap={1}>
            {filters.map(filter => (
              <FilterButton
                key={filter.id}
                selected={selectedFilter === filter.id}
                onClick={() => setSelectedFilter(filter.id)}
              >
                {filter.label}
                <Chip
                  size="small"
                  label={filter.id === 'all' 
                    ? jobs.length 
                    : jobs.filter(job => job.status === filter.id).length
                  }
                  sx={{ 
                    ml: 1,
                    bgcolor: selectedFilter === filter.id 
                      ? 'rgba(255,255,255,0.2)' 
                      : 'rgba(0,0,0,0.08)',
                    color: selectedFilter === filter.id 
                      ? 'white' 
                      : 'text.secondary',
                  }}
                />
              </FilterButton>
            ))}
          </Stack>
        </Container>
      </GradientHeader>

      {/* Main content */}
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Stack spacing={2}>
          {filteredJobs.map((job) => (
            <JobCard
            key={job.id}
            onMouseEnter={() => setHoveredId(job.id)}
            onMouseLeave={() => setHoveredId(null)}
          >
            <Box 
              sx={{ 
                display: 'flex', 
                flexDirection: { xs: 'column', sm: 'row' }, 
                justifyContent: 'space-between',
                alignItems: 'center' // Align items properly
              }}
            >
              {/* Company Logo */}
              <Box 
                sx={{ 
                  width: 60, 
                  height: 60, 
                  flexShrink: 0, 
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'center', 
                  mr: 2 // Add margin for spacing
                }}
              >
                <img 
                  src={job.companyLogo} 
                  alt={`${job.company} logo`} 
                  style={{ width: "100%", height: "100%", objectFit: "contain", borderRadius: 8 }} 
                />
              </Box>
          
              <Box sx={{ flex: 1 }}>
                <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 1 }}>
                  <Typography variant="h6" sx={{ fontWeight: 600, fontSize: '1.25rem' }}>
                    {job.title}
                  </Typography>
                  <StyledChip 
                    label={job.type} 
                    color="warning"
                    size="small"
                  />
                  <StyledChip 
                    label={job.status.charAt(0).toUpperCase() + job.status.slice(1)}
                    color={job.status === 'live' ? 'success' : 'default'}
                    size="small"
                  />
                </Stack>
          
                <Stack 
                  direction="row" 
                  spacing={2} 
                  flexWrap="wrap" 
                  gap={2}
                  sx={{ mt: 1 }}
                >
                  <IconText>
                    <BusinessIcon />
                    {job.company}
                  </IconText>
                  <IconText>
                    <LocationIcon />
                    {job.location}
                  </IconText>
                  <IconText>
                    <MoneyIcon />
                    {job.stipend}
                  </IconText>
                  <IconText>
                    <TimeIcon />
                    {job.postedDate}
                  </IconText>
                </Stack>
              </Box>
          
              {job.status === 'live' && (
                <Box sx={{ mt: { xs: 2, sm: 0 }, ml: { xs: 0, sm: 2 } }}>
                  <ApplyButton
                    variant="outlined"
                    hovered={hoveredId === job.id}
                    sx={{ minWidth: 120 }}
                    onClick={() => handleApply(job)}
                    component={job.type === "Internship" ? "a" : "button"}
                    href={job.type === "Internship" ? job.applicationLink : undefined}
                    target={job.type === "Internship" ? "_blank" : undefined}
                    rel={job.type === "Internship" ? "noopener noreferrer" : undefined}
                  >
                    Apply Now
                  </ApplyButton>
                </Box>
              )}
            </Box>
          </JobCard>
          
          ))}
        </Stack>
      </Container>
    </Box>
  );
};

export default JobListings;