import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// Define custom theme with orange color
const theme = createTheme({
  palette: {
    primary: {
      main: '#ef740d',
    },
  },
});

// Define your recent launches data here with the new format and numeric IDs
const recentLaunches = [
  {
    id: 1,
    date: "March 24, 2025",
    heading: "The most awaited \"Internship Simulation\" feature is now live on website.",
    points: [
      "Internship Simulation is a new feature that allows students to experience a real-time corporate environment during their internship.",
      "It helps students understand how an actual company project works, improving practical skills and job readiness."
    ],
    image: "/images/internship.png",
    buttonLink: "https://dashboard.internshipstudio.com/internship",
    buttonText: "Explore Now",
    isNew: true
  },
  {
    id: 2,
    date: "March 21, 2025",
    heading: "Exciting News - 'Industry Projects' Now Available!",
    points: [
      "Work on meaningful industry projects while earning valuable mentorship or cash rewards.",
      "Build your professional portfolio with experience that sets you apart from other candidates."
    ],
    image: "/images/industry_project.jpg",
    buttonLink: "https://forms.gle/K6WiJihu5XyFyacAA",
    buttonText: "Apply Now",
    isNew: true
  },
  {
    id: 3,
    date: "March 19, 2025",
    heading: "Verified Internship Channel on WhatsApp",
    points: [
      "Join this WhatsApp channel to get updates of all internships & fresher jobs opportunities.",
      "Follow this WhatsApp Channel to get instant updates of all internship & freshers jobs."
    ],
    image: "/images/whatsapp_channel.png",
    buttonLink: "https://whatsapp.com/channel/0029VbALkpa6rsQxzwHG1Q1k",
    buttonText: "Join Channel",
    isNew: true
  },
];

const RecentLaunchesDrawer = ({ open, onClose }) => {
  // Helper function to check if image is valid
  const hasValidImage = (image) => {
    return image && image.trim() !== "";
  };

  return (
    <ThemeProvider theme={theme}>
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: { 
            width: { xs: '100%', sm: 400 },
            maxWidth: '100%' 
          }
        }}
      >
        <Box sx={{ p: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h5" component="div" sx={{ fontWeight: 'bold', color: '#ef740d' }}>
              Recent Launches 🚀
            </Typography>
            <IconButton onClick={onClose} aria-label="close" sx={{ color: '#ef740d' }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider sx={{ mb: 3, borderColor: 'rgba(239, 116, 13, 0.3)' }} />
          
          <List sx={{ px: 0 }}>
            {recentLaunches.map((launch, index) => (
              <React.Fragment key={launch.id}>
                <Card 
                  sx={{ 
                    mb: 3, 
                    borderRadius: 2,  
                    boxShadow: '0 3px 10px rgba(0,0,0,0.08)',
                    position: 'relative',
                    overflow: 'visible'
                  }}
                >
                  {launch.isNew && (
                    <Box 
                      sx={{
                        position: 'absolute',
                        top: -10,
                        right: -10,
                        background: 'linear-gradient(135deg, #ef740d, #ff9f4a)',
                        color: 'white',
                        borderRadius: '50%',
                        width: 40,
                        height: 40,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 1,
                        boxShadow: '0 2px 5px rgba(239, 116, 13, 0.5)'
                      }}
                    >
                      <NewReleasesIcon fontSize="small" />
                    </Box>
                  )}
                  
                  {/* Only render CardMedia if there's a valid image */}
                  {hasValidImage(launch.image) && (
                    <Box sx={{ maxHeight: '280px', overflow: 'hidden' }}>
                      <CardMedia
                        component="img"
                        sx={{
                          width: '100%',
                          objectFit: 'cover',
                          objectPosition: 'center'
                        }}
                        image={launch.image}
                        alt={launch.heading}
                      />
                    </Box>
                  )}
                  
                  <CardContent>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                      <CalendarTodayIcon fontSize="small" sx={{ color: '#ef740d', mr: 1 }} />
                      <Typography variant="caption" color="text.secondary">
                        {launch.date}
                      </Typography>
                    </Box>
                    
                    <Typography 
                      variant="h6" 
                      component="div" 
                      sx={{ 
                        fontWeight: 'bold',
                        mb: 1.5,
                        color: '#222'
                      }}
                    >
                      {launch.heading}
                    </Typography>
                    
                    <Box component="ul" sx={{ pl: 2, mt: 0, mb: 0 }}>
                      {launch.points.map((point, i) => (
                        <Typography 
                          component="li" 
                          variant="body2" 
                          key={i} 
                          sx={{ 
                            mb: 0.5, 
                            color: '#555',
                            display: 'list-item',
                            listStyleType: 'disc'
                          }}
                        >
                          {point}
                        </Typography>
                      ))}
                    </Box>
                  </CardContent>
                  
                  <CardActions sx={{ px: 2, pb: 2 }}>
                    <Button 
                      variant="contained" 
                      endIcon={<ArrowForwardIcon />}
                      href={launch.buttonLink}
                      fullWidth
                      sx={{ 
                        textTransform: 'none', 
                        borderRadius: 1.5,
                        py: 1,
                        background: 'linear-gradient(to right, #ef740d, #ff9f4a)',
                        '&:hover': {
                          background: 'linear-gradient(to right, #ef740d, #ef740d)'
                        }
                      }}
                    >
                      {launch.buttonText || "Join Now"}
                    </Button>
                  </CardActions>
                </Card>
                
                {index < recentLaunches.length - 1 && (
                  <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
                    <Box sx={{ width: '50%', borderBottom: '1px dashed rgba(239, 116, 13, 0.3)' }} />
                  </Box>
                )}
              </React.Fragment>
            ))}
          </List>
        </Box>
      </Drawer>
    </ThemeProvider>
  );
};

export default RecentLaunchesDrawer;