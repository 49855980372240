export const countDownHandler = () => {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const start = new Date(today);
  start.setHours(0, 0, 0, 0);
  const end = new Date(today);
  end.setHours(23, 59, 59, 59);
  const reset = new Date(tomorrow);
  reset.setHours(0, 0, 0, 0);
  const resetEnd = new Date(tomorrow);
  resetEnd.setHours(23, 59, 59, 59);
  let timeLeft = 0;
  if (today < start) {
    timeLeft = start - today;
  } else if (today > start && today < end) {
    timeLeft = end - today;
  } else if (today > end && today < reset) {
    timeLeft = reset - today;
  } else if (today > reset && today < resetEnd) {
    timeLeft = resetEnd - today;
  }
  const hours = Math.floor(
    (timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
  return [hours, minutes, seconds];
};
