import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Container,
  Paper,
  Chip,
  Stack,
  styled,
  alpha,
} from "@mui/material";
import {
  Business as BusinessIcon,
  LocationOn as LocationIcon,
  CurrencyRupee as MoneyIcon,
  AccessTime as TimeIcon,
} from "@mui/icons-material";
import { apiFetchInternship, apiFetchJobs } from "../../utils/api";

// Styled Components
const GradientHeader = styled(Box)(({ theme }) => ({
  background: `linear-gradient(to right, ${alpha(
    theme.palette.warning.light,
    0.1
  )}, ${alpha(theme.palette.warning.light, 0.2)})`,
  padding: theme.spacing(6, 0),
}));

const FilterButton = styled(Button)(({ theme, selected }) => ({
  backgroundColor: selected
    ? theme.palette.warning.main
    : theme.palette.common.white,
  color: selected ? theme.palette.common.white : theme.palette.text.secondary,
  fontWeight: 500,
  borderRadius: theme.shape.borderRadius * 2,
  padding: theme.spacing(1, 2),
  "&:hover": {
    backgroundColor: selected
      ? theme.palette.warning.dark
      : alpha(theme.palette.warning.light, 0.1),
  },
  "& .MuiChip-root": {
    marginLeft: theme.spacing(1),
  },
}));

const JobCard = styled(Paper)(({ theme }) => ({
  border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
  borderRadius: theme.shape.borderRadius * 2,
  padding: theme.spacing(3),
  transition: "all 0.3s ease",
  boxShadow: "none",
  display: "flex",
  flexDirection: "column",
  height: "100%",
  "&:hover": {
    border: `1px solid ${theme.palette.warning.light}`,
    boxShadow: theme.shadows[1],
  },
}));

const IconText = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(0.5),
  color: theme.palette.text.secondary,
  fontSize: "0.875rem",
  "& svg": {
    fontSize: "1rem",
    color: theme.palette.text.secondary,
  },
}));

const StyledChip = styled(Chip)(({ theme, color = "default" }) => ({
  borderRadius: theme.shape.borderRadius * 2,
  fontWeight: 500,
  height: 28,
  ...(color === "warning" && {
    backgroundColor: alpha(theme.palette.warning.main, 0.1),
    color: theme.palette.warning.dark,
  }),
  ...(color === "success" && {
    backgroundColor: alpha(theme.palette.success.main, 0.1),
    color: theme.palette.success.dark,
  }),
  ...(color === "default" && {
    backgroundColor: alpha(theme.palette.grey[500], 0.1),
    color: theme.palette.text.secondary,
  }),
}));

const ApplyButton = styled(Button)(({ theme, hovered }) => ({
  padding: theme.spacing(1, 3),
  borderRadius: theme.shape.borderRadius * 2,
  fontWeight: 500,
  borderWidth: 2,
  textDecoration: "none",
  ...(hovered
    ? {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.common.white,
        borderColor: theme.palette.warning.main,
        "&:hover": {
          backgroundColor: theme.palette.warning.dark,
          borderColor: theme.palette.warning.dark,
        },
      }
    : {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.warning.main,
        borderColor: theme.palette.warning.main,
        "&:hover": {
          backgroundColor: alpha(theme.palette.warning.light, 0.1),
          borderColor: theme.palette.warning.main,
        },
      }),
}));

const Internship = () => {
  const [hoveredId, setHoveredId] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [internship, setInternship] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await apiFetchInternship();
        if (response.success) {
          setInternship(response.data);
        } else {
          setError(response.message || "Failed to load jobs.");
        }
      } catch (err) {
        console.error("Fetch Error:", err);
        setError("Something went wrong while fetching jobs.");
      } finally {
        setLoading(false);
      }
    };
    fetchJobs();
  }, []);

  const filters = [
    { id: "all", label: "All" },
    { id: "tech", label: "Tech" },
    { id: "data", label: "Data" },
    { id: "management", label: "Management" },
    { id: "design", label: "Design" },
    { id: "other", label: "Other" },
  ];

  const filteredInternship = internship.filter((internship) =>
    selectedFilter === "all"
      ? true
      : internship.category.toLowerCase() === selectedFilter.toLowerCase()
  );


  // Helper function to handle apply button click
  const handleApply = (job) => {
    if (job.type === "Internship" && job.applicationLink) {
      window.open(job.applicationLink, "_blank");
    }
  };

  return (
    <Box sx={{ minHeight: "100vh", bgcolor: "background.default" }}>
      {/* Header with filters */}
      <GradientHeader>
        <Container maxWidth="lg">
          <Typography
            variant="h3"
            component="h1"
            sx={{
              fontWeight: 700,
              color: "text.primary",
              mb: 3,
              fontSize: { xs: "2rem", md: "2.5rem" },
            }}
          >
            Available Internship Domains
          </Typography>

          <Stack direction="row" spacing={1} flexWrap="wrap" gap={1}>
            {filters.map((filter) => (
              <FilterButton
                key={filter.id}
                selected={selectedFilter === filter.id}
                onClick={() => setSelectedFilter(filter.id)}
              >
                {filter.label}
                <Chip
                  size="small"
                  label={
                    filter.id === "all"
                      ? internship.length
                      : internship.filter(
                          (job) =>
                            job.category.toLowerCase() ===
                            filter.id.toLowerCase()
                        ).length
                  }
                  sx={{
                    ml: 1,
                    bgcolor:
                      selectedFilter === filter.id
                        ? "rgba(255,255,255,0.2)"
                        : "rgba(0,0,0,0.08)",
                    color:
                      selectedFilter === filter.id ? "white" : "text.secondary",
                  }}
                />
              </FilterButton>
            ))}
          </Stack>
        </Container>
      </GradientHeader>

      {/* Main content */}
      <Container maxWidth="lg" sx={{ py: 6 }}>
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", py: 4 }}>
            <Typography>Loading internships...</Typography>
          </Box>
        ) : error ? (
          <Box sx={{ display: "flex", justifyContent: "center", py: 4 }}>
            <Typography color="error">{error}</Typography>
          </Box>
        ) : filteredInternship.length === 0 ? (
          <Box sx={{ display: "flex", justifyContent: "center", py: 4 }}>
            <Typography>No internships found matching your filter.</Typography>
          </Box>
        ) : (
          <>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xs: "1fr",
                  sm: "1fr 1fr",
                  md: "1fr 1fr 1fr 1fr",
                },
                gap: 3,
              }}
            >
              {filteredInternship.map((internship) => (
                <JobCard
                  key={internship.id}
                  onMouseEnter={() => setHoveredId(internship.id)}
                  onMouseLeave={() => setHoveredId(null)}
                >
                  {internship.isBestSeller && (
                    <Box
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        bgcolor: "warning.main",
                        color: "common.white",
                        px: 1,
                        py: 0.5,
                        fontWeight: 600,
                        fontSize: "0.75rem",
                        transform:
                          "rotate(-45deg) translateX(-28%) translateY(-10%)",
                        transformOrigin: "top left",
                        zIndex: 1,
                        borderTopLeftRadius: 4,
                        width: 120,
                        textAlign: "center",
                      }}
                    >
                      BEST SELLER
                    </Box>
                  )}

                  <Box
                    sx={{
                      height: 160,
                      mb: 2,
                      borderRadius: 2,
                      overflow: "hidden",
                      position: "relative",
                    }}
                  >
                    <img
                      src={internship.image}
                      alt={internship.title}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </Box>

                  <Typography
                    variant="h6"
                    component="h2"
                    sx={{ fontWeight: 700, mb: 1, minHeight: 56 }}
                  >
                    {internship.title}
                  </Typography>

                  <Stack spacing={1} sx={{ mb: 2 }}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <Box component="span" sx={{ color: "warning.main" }}>
                        <Box
                          component="span"
                          sx={{ display: "inline-flex", alignItems: "center" }}
                        >
                          🏆
                        </Box>
                      </Box>
                      <Typography
                        variant="body2"
                        sx={{ color: "text.secondary" }}
                      >
                        Internship Certificate
                      </Typography>
                    </Box>

                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <Box component="span" sx={{ color: "warning.main" }}>
                        <Box
                          component="span"
                          sx={{ display: "inline-flex", alignItems: "center" }}
                        >
                          🏢
                        </Box>
                      </Box>
                      <Typography
                        variant="body2"
                        sx={{ color: "text.secondary" }}
                      >
                        Placement Assistance
                      </Typography>
                    </Box>

                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <Box component="span" sx={{ color: "warning.main" }}>
                        <Box
                          component="span"
                          sx={{ display: "inline-flex", alignItems: "center" }}
                        >
                          ✅
                        </Box>
                      </Box>
                      <Typography
                        variant="body2"
                        sx={{ color: "text.secondary" }}
                      >
                        Lifetime access
                      </Typography>
                    </Box>

                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <Box component="span" sx={{ color: "warning.main" }}>
                        <Box
                          component="span"
                          sx={{ display: "inline-flex", alignItems: "center" }}
                        >
                          ✉️
                        </Box>
                      </Box>
                      <Typography
                        variant="body2"
                        sx={{ color: "text.secondary" }}
                      >
                        Offer letter
                      </Typography>
                    </Box>
                  </Stack>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      mb: 2,
                    }}
                  >
                    <Typography
                      component="span"
                      sx={{
                        textDecoration: "line-through",
                        color: "text.secondary",
                        fontSize: "0.875rem",
                      }}
                    >
                      ₹8500
                    </Typography>
                    <Typography
                      component="span"
                      sx={{
                        fontWeight: 700,
                        fontSize: "1.25rem",
                        color: "text.primary",
                      }}
                    >
                      {internship.price}
                    </Typography>

                    {/* 75% OFF Tag */}
                    <Box
                      component="span"
                      sx={{
                        display: "inline-flex",
                        alignItems: "center",
                        justifyContent: "center",
                        bgcolor: "error.main",
                        color: "common.white",
                        px: 1,
                        py: 0.5,
                        borderRadius: 1,
                        fontSize: "0.75rem",
                        fontWeight: 700,
                        ml: 1,
                        animation: "pulse 1.5s infinite",
                        "@keyframes pulse": {
                          "0%": {
                            transform: "scale(1)",
                          },
                          "50%": {
                            transform: "scale(1.05)",
                          },
                          "100%": {
                            transform: "scale(1)",
                          },
                        },
                      }}
                    >
                      75% OFF
                    </Box>
                  </Box>

                  {/* Spacer to push the button to the bottom */}
                  <Box sx={{ flexGrow: 1 }} />

                  <Button
                    fullWidth
                    variant="contained"
                    color="warning"
                    sx={{
                      borderRadius: 2,
                      py: 1.5,
                      textTransform: "none",
                      fontWeight: 600,
                      mt: 'auto'
                    }}
                    onClick={() =>
                      window.open("https://rzp.io/rzp/bGEuzkj", "_blank")
                    }
                  >
                    Enroll Now
                  </Button>
                </JobCard>
              ))}
            </Box>

            {filteredInternship.length > 8 && (
              <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                <Button
                  variant="contained"
                  color="warning"
                  sx={{
                    borderRadius: 2,
                    px: 4,
                    py: 1.5,
                    textTransform: "none",
                    fontWeight: 600,
                  }}
                >
                  Show More
                </Button>
              </Box>
            )}
          </>
        )}
      </Container>
    </Box>
  );
};

export default Internship;