import React from "react";

import CallIcon from "@mui/icons-material/Call";

import "./Note.css";
import { Button } from "@mui/material";

const Contact = () => {
  return (
    <div className="footer-fixed-inner landing">
      <Button
        className="primaryBackground"
        variant="contained"
        size="large"
        startIcon={<CallIcon />}
        component="a"
        href="tel:+918830657968"
      >
        Enquire Now
      </Button>
    </div>
  );
};

export default Contact;
