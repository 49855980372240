import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Input,
  Select,
  MenuItem,
  Card,
  CardContent,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Container
} from '@mui/material';
import { Add, Edit, Delete, Search } from '@mui/icons-material';

const JobListingsAdmin = () => {
  const [jobs, setJobs] = useState([
    {
      id: "frontend-intern-001",
      title: "Frontend Developer Intern",
      company: "TechCorp",
      location: "Remote",
      stipend: "20,000/month",
      type: "Internship",
      postedDate: "2024-02-11",
      status: "live",
      applicationLink: "https://forms.google.com/frontend-intern-001"
    }
  ]);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [jobToDelete, setJobToDelete] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentJob, setCurrentJob] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    company: '',
    location: '',
    stipend: '',
    type: 'Internship',
    status: 'live',
    applicationLink: ''
  });

  const handleOpenDialog = (job = null) => {
    if (job) {
      setFormData(job);
      setCurrentJob(job);
    } else {
      setFormData({
        title: '',
        company: '',
        location: '',
        stipend: '',
        type: 'Internship',
        status: 'live',
        applicationLink: ''
      });
      setCurrentJob(null);
    }
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setCurrentJob(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = () => {
    if (currentJob) {
      setJobs(prev => prev.map(job => 
        job.id === currentJob.id ? { ...formData, id: job.id } : job
      ));
    } else {
      const newJob = {
        ...formData,
        id: `job-${Date.now()}`,
        postedDate: new Date().toISOString().split('T')[0]
      };
      setJobs(prev => [...prev, newJob]);
    }
    handleCloseDialog();
  };

  const handleDeleteClick = (job) => {
    setJobToDelete(job);
    setIsDeleteDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    if (jobToDelete) {
      setJobs(prev => prev.filter(job => job.id !== jobToDelete.id));
      setIsDeleteDialogOpen(false);
      setJobToDelete(null);
    }
  };

  const filteredJobs = jobs.filter(job => 
    job.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    job.company.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Box sx={{ minHeight: '100vh', bgcolor: 'background.default', py: 4 }}>
      <Container maxWidth="lg">
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
          <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold' }}>
            Job Listings Management
          </Typography>
          <Button 
            variant="contained" 
            color="primary"
            onClick={() => handleOpenDialog()}
            startIcon={<Add />}
          >
            Add New Job
          </Button>
        </Box>

        {/* Search Bar */}
        <Box sx={{ mb: 3, position: 'relative' }}>
          <Search sx={{ position: 'absolute', left: 2, top: '50%', transform: 'translateY(-50%)', color: 'text.secondary', ml: 1 }} />
          <Input
            fullWidth
            placeholder="Search jobs..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            sx={{ pl: 5 }}
          />
        </Box>

        {/* Jobs Table */}
        <Card>
          <CardContent sx={{ p: 0 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Title</TableCell>
                  <TableCell>Company</TableCell>
                  <TableCell>Location</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Posted Date</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredJobs.map((job) => (
                  <TableRow key={job.id}>
                    <TableCell>{job.title}</TableCell>
                    <TableCell>{job.company}</TableCell>
                    <TableCell>{job.location}</TableCell>
                    <TableCell>{job.type}</TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          px: 2,
                          py: 0.5,
                          borderRadius: 4,
                          display: 'inline-block',
                          bgcolor: job.status === 'live' ? 'success.light' : 'grey.200',
                          color: job.status === 'live' ? 'success.dark' : 'text.secondary'
                        }}
                      >
                        {job.status}
                      </Box>
                    </TableCell>
                    <TableCell>{job.postedDate}</TableCell>
                    <TableCell>
                      <Box sx={{ display: 'flex', gap: 1 }}>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => handleOpenDialog(job)}
                          startIcon={<Edit />}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="outlined"
                          size="small"
                          color="error"
                          onClick={() => handleDeleteClick(job)}
                          startIcon={<Delete />}
                        >
                          Delete
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>

        {/* Add/Edit Dialog */}
        <Dialog open={isDialogOpen} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
          <DialogTitle>
            {currentJob ? 'Edit Job Listing' : 'Add New Job Listing'}
          </DialogTitle>
          <DialogContent>
            <Box sx={{ display: 'grid', gap: 2, pt: 2 }}>
              <FormControl fullWidth>
                <InputLabel htmlFor="title">Job Title</InputLabel>
                <Input
                  id="title"
                  name="title"
                  value={formData.title}
                  onChange={handleInputChange}
                />
              </FormControl>

              <FormControl fullWidth>
                <InputLabel htmlFor="company">Company</InputLabel>
                <Input
                  id="company"
                  name="company"
                  value={formData.company}
                  onChange={handleInputChange}
                />
              </FormControl>

              <FormControl fullWidth>
                <InputLabel htmlFor="location">Location</InputLabel>
                <Input
                  id="location"
                  name="location"
                  value={formData.location}
                  onChange={handleInputChange}
                />
              </FormControl>

              <FormControl fullWidth>
                <InputLabel htmlFor="stipend">Stipend</InputLabel>
                <Input
                  id="stipend"
                  name="stipend"
                  value={formData.stipend}
                  onChange={handleInputChange}
                />
              </FormControl>

              <FormControl fullWidth>
                <InputLabel htmlFor="type">Job Type</InputLabel>
                <Select
                  id="type"
                  name="type"
                  value={formData.type}
                  onChange={handleInputChange}
                >
                  <MenuItem value="Internship">Internship</MenuItem>
                  <MenuItem value="Full-time">Full-time</MenuItem>
                  <MenuItem value="Part-time">Part-time</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel htmlFor="status">Status</InputLabel>
                <Select
                  id="status"
                  name="status"
                  value={formData.status}
                  onChange={handleInputChange}
                >
                  <MenuItem value="live">Live</MenuItem>
                  <MenuItem value="hired">Hired</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel htmlFor="applicationLink">Application Link</InputLabel>
                <Input
                  id="applicationLink"
                  name="applicationLink"
                  value={formData.applicationLink}
                  onChange={handleInputChange}
                />
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Cancel</Button>
            <Button onClick={handleSubmit} variant="contained" color="primary">
              {currentJob ? 'Update' : 'Add'} Job
            </Button>
          </DialogActions>
        </Dialog>

        {/* Delete Confirmation Dialog */}
        <Dialog open={isDeleteDialogOpen} onClose={() => setIsDeleteDialogOpen(false)}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this job listing? This action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsDeleteDialogOpen(false)}>Cancel</Button>
            <Button onClick={handleConfirmDelete} variant="contained" color="error">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Box>
  );
};

export default JobListingsAdmin;