import React from "react";
import { Link, useLocation } from "react-router-dom";

import CTAModal from "../CTAModal/CTAModal";
import { countDownHandler } from "../../misc/oddJobs";

import Arrow from "../../img/arrow2.webp";

import EventNoteIcon from "@mui/icons-material/EventNote";

import "./Note.css";
import { Button, Typography } from "@mui/material";

const Note = () => {
  const [hours, setHours] = React.useState(0);
  const [minutes, setMinutes] = React.useState(0);
  const [seconds, setSeconds] = React.useState(0);

  const { pathname } = useLocation();

  const isMobile = window.innerWidth <= 768;

  React.useEffect(() => {
    setHours(countDownHandler()[0]);
    setMinutes(countDownHandler()[1]);
    setSeconds(countDownHandler()[2]);

    const interval = setInterval(() => {
      setHours(countDownHandler()[0]);
      setMinutes(countDownHandler()[1]);
      setSeconds(countDownHandler()[2]);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const exclude = [
    "advanced-certificate-in-digital-marketing-with-job-guarantee",
    "landing",
  ];

  return (
    <>
      {pathname.includes("common-internship-test") ? (
        <>
          {isMobile && (
            <div
              className="footer-fixed-inner"
              style={{
                padding: "0px",
                backgroundColor: "transparent",
                top: pathname.includes("landing") ? "0" : "auto",
                bottom: pathname.includes("landing") ? "auto" : "0",
              }}
            >
              <button
                className="custom-btn btn-11 btn-11-alt"
                style={{
                  margin: "0 auto",
                }}
                onClick={() => {
                  window.open(
                    "https://cit.internshipstudio.com/register",
                    "_blank"
                  );
                }}
              >
                Register Now<div className="dot"></div>
              </button>
            </div>
          )}
        </>
      ) : (
        <div
          className="footer-fixed-inner"
          style={{
            top: pathname.includes("landing") ? "0" : "auto",
            bottom: pathname.includes("landing") ? "auto" : "0",
          }}
        >
          {pathname.includes(
            "advanced-certificate-in-digital-marketing-with-job-guarantee"
          ) &&
            !pathname.includes("landing") && (
              <div className="arrow">
                <img src={Arrow} alt="arrow" />
              </div>
            )}
          <p className="h3">
            {exclude.some((item) => pathname.includes(item)) ? (
              <>
                Book a free demo and get <strong>Assured Scholarships.</strong>
              </>
            ) : (
              <>
                Register for CIT'25 &amp; get{" "}
                <strong>Guaranteed Internship</strong> in various domains.
              </>
            )}
          </p>

          {exclude.some((item) => pathname.includes(item)) ? (
            <div className="countdown">
              <p
                style={{
                  fontWeight: "bold",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <small
                  style={{
                    fontWeight: "normal",
                    fontSize: "0.8rem",
                  }}
                >
                  (Time Remaining)
                </small>
                <span>
                  {hours}H {minutes}M {seconds}S
                </span>
              </p>
            </div>
          ) : null}

          {exclude.some((item) => pathname.includes(item)) ? (
            <CTAModal
              text="Book a Free Demo"
              logo={true}
              page={pathname}
              type="footer"
            />
          ) : (
            <>
              <Typography
                variant="small"
                sx={{
                  display: {
                    xs: "",
                    md: "none",
                  },
                  width: "50%",
                  marginRight: "10px",
                }}
              >
                Register for CIT'25 &amp; get{" "}
                <strong>Guaranteed Internship</strong>
              </Typography>
              <Button
                variant="contained"
                className="primaryBackground"
                component={Link}
                to="/common-internship-test"
                startIcon={<EventNoteIcon />}
              >
                View Details
              </Button>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default Note;
